.create-schedule-form .react-datepicker__time-list {
  padding: 5px 10px;
}

.create-schedule-form .react-datepicker-wrapper,
.create-schedule-form .react-datepicker__input-container {
  width: 100%;
}

.create-schedule-form .react-datepicker__time-container,
.create-schedule-form .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100px;
}

.create-schedule-form .react-datepicker {
  height: 100%;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #007bff;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #007bff;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
  background-color: #7ac2ff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover {
  background-color: #007bff;
}

.react-datepicker__day--keyboard-selected {
  background-color: #007bff;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: #007bff;
}

.react-datepicker__close-icon::after {
  background-color: #6c6c6c;
}

.modalCardOverlay {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.modalCard {
  display: flex;
  flex-direction: column;
  position: relative;
  a:not(.btn) {
    color: #222;
    cursor: pointer;
    &:visited {color: #222;}
    &:active, &:focus {color: #444;}
    &:hover { text-decoration: none; color: #666;}
  }
  .close {
    position: absolute;
    right: -20px;
    top: -20px;
    font-size: 24px;
    font-weight: 700;
    background-color: transparent;
    border: none;
    padding: 3px 10px;
  }
}
