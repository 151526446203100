@import "Extentions";

.contentRate {
  p {
    margin-bottom: 0;
  }
}

.topRates {
  #rap-overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    z-index: 2;
    cursor: pointer;
  }

  .rap-manager {
    width: 50%;
  }

  .rap-manager,
  .rap-target-container {
    position: relative;
  }

  .rap-left {
    left: -20px !important;
  }
  .rap-bottom {
    top: 15px !important;
  }
  .rap-top {
    top: -15px !important;
  }
  .rap-right {
    left: 20px !important;
  }

  .ar {
    transform: rotate(-180deg);
  }
  .at {
    transform: rotate(90deg);
  }
  .ab {
    transform: rotate(-90deg);
  }

  .rap-popover-content {
    background: white;
    box-shadow: 0 0 4px 0 rgba(68,68,68,1);
    border-radius: 8px;
    border: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .pad {
    padding: 10px;
    width: 260px;
  }
}


