body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.create-schedule-form .react-datepicker__time-list {
  padding: 5px 10px; }

.create-schedule-form .react-datepicker-wrapper,
.create-schedule-form .react-datepicker__input-container {
  width: 100%; }

.create-schedule-form .react-datepicker__time-container,
.create-schedule-form .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100px; }

.create-schedule-form .react-datepicker {
  height: 100%; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #007bff;
  color: white;
  font-weight: bold; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #007bff; }

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
  background-color: #7ac2ff; }

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover {
  background-color: #007bff; }

.react-datepicker__day--keyboard-selected {
  background-color: #007bff; }

.react-datepicker__day--keyboard-selected:hover {
  background-color: #007bff; }

.react-datepicker__close-icon::after {
  background-color: #6c6c6c; }

.modalCardOverlay {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); }

.modalCard {
  display: flex;
  flex-direction: column;
  position: relative; }
  .modalCard a:not(.btn) {
    color: #222;
    cursor: pointer; }
    .modalCard a:not(.btn):visited {
      color: #222; }
    .modalCard a:not(.btn):active, .modalCard a:not(.btn):focus {
      color: #444; }
    .modalCard a:not(.btn):hover {
      text-decoration: none;
      color: #666; }
  .modalCard .close {
    position: absolute;
    right: -20px;
    top: -20px;
    font-size: 24px;
    font-weight: 700;
    background-color: transparent;
    border: none;
    padding: 3px 10px; }

.contentRate p {
  margin-bottom: 0; }

.topRates #rap-overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 2;
  cursor: pointer; }

.topRates .rap-manager {
  width: 50%; }

.topRates .rap-manager,
.topRates .rap-target-container {
  position: relative; }

.topRates .rap-left {
  left: -20px !important; }

.topRates .rap-bottom {
  top: 15px !important; }

.topRates .rap-top {
  top: -15px !important; }

.topRates .rap-right {
  left: 20px !important; }

.topRates .ar {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }

.topRates .at {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.topRates .ab {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }

.topRates .rap-popover-content {
  background: white;
  box-shadow: 0 0 4px 0 #444444;
  border-radius: 8px;
  border: 0;
  overflow: hidden;
  text-overflow: ellipsis; }

.topRates .pad {
  padding: 10px;
  width: 260px; }

body {
  height: auto; }

.anticon {
  font-size: 0.8rem;
  line-height: 100%;
  vertical-align: baseline; }

.ant-empty-normal {
  color: #e6e6e6; }

.ant-table-pagination.ant-pagination {
  float: left; }

.ant-table-placeholder {
  padding: 10px;
  color: #e6e6e6; }

.ant-table-thead > tr > th {
  color: #292929;
  text-transform: uppercase;
  font-size: 0.7rem; }

.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  padding: 10px 0; }

svg {
  width: auto; }

.ant-pagination-item-link {
  display: block; }

.ant-table-thead > tr > th.ant-table-column-has-actions {
  position: relative;
  height: 100%; }

.ant-table-thead > tr > th .anticon-filter > svg, .ant-table-thead > tr > th .ant-table-filter-icon > svg {
  margin-left: -14px; }

