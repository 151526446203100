$body-background: #e2e2e2;
$background-color-light:    rgba(26, 13, 51, 0.81);
//$component-bg-1:            #352e5d;
//$component-bg-2:            lighten($component-bg-1, 10%);

$primary-color: #4497f8;
$link-color: #475e77;

$bg-color-gray-1: #f4f4f4;

$border-color-base:         rgba(255, 255, 255, 0.2);

$text-color:                #fff;
$text-gray-1:               darken($text-color, 10%);
$text-gray-2:               darken($text-color, 20%);

$color-gray-1: #292929;
$color-gray-2: #fafafa;

$color-white: #fff;
$color-black: rgba(41, 41, 41, 0.9);
