@import "./variables";

body {
  height: auto;
}

.anticon {
  font-size: 0.8rem;
  line-height: 100%;
  vertical-align: baseline;

}

.ant-empty-normal {
  color: $text-gray-1;
}



// tables
.ant-table-pagination.ant-pagination {
  float: left;
}

.ant-table-placeholder {
  padding: 10px;
  color: #e6e6e6;
}

.ant-table-thead > tr > th {
  color: $color-gray-1;
  text-transform: uppercase;
  font-size: 0.7rem;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  padding: 10px 0;
}

//.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
//.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
//.ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td,
//.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
//  background-color: $color-white;
//}
//
//.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
//.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
//.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
//.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
//  background-color: $color-white;
//}

svg {
  width: auto;
}
.ant-pagination-item-link {
  display: block;
}

.ant-table-thead > tr > th.ant-table-column-has-actions {
  position: relative;
  height: 100%;
}

.ant-table-thead > tr > th .anticon-filter > svg, .ant-table-thead > tr > th .ant-table-filter-icon > svg {

  margin-left: -14px;
}
